.cont{
    font-size: 15px;
    line-height: 18px;
    span{
        display: inline-block;
        width: 70px;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: underline;
        }
    }
}
.links{
    @extend .row;
    justify-content: center;

    div{
        @extend %col-md-3;
        @extend %col-sm-3;
        @extend %col-6;
        margin-bottom: 25px;
        a{
            width: 100%;
            height: 100%;
            background-color: rgba(131,111,65,.1);
            text-align: center;
            display: block;
            padding: 15px;
            color: #836f41;
            font-weight: bold;
            text-decoration: none;
            font-size: 15px;
            padding-top: 25px;
            padding-bottom: 25px;
            img{
                display: block;
                max-width: 80px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 25px;
            }
        }
    }
}
.pager{
    @extend %col-12;
    text-align: center;
    a{
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px;
        text-decoration: none;
        border-radius: 8px;
        width: 40px;
        font-size: 15px;
        i{
            font-size: 20px;
        }
        &:not(.bt){
            background-color: rgba(4, 120, 50,.1);
            &:hover,&.active{
                background-color: rgba(4, 120, 50,1);
                color: white;
            }
        }
        &.bt{
            position: relative;
            top:3px;
        }
        &.prev{
            top: -1px;
            transform: rotate(180deg);
        }
    }
}
.contacts{
    @extend .row;
    margin-top: 50px;
    .contact{
        @extend %col-sm-4;
        margin-bottom: 25px;
    }
}
.article{
    @extend .row;
    @extend .stretch;
    padding-top: 40px;
    padding-bottom: 40px;
    .text{
        @extend %col-sm-7;
        h1,h2,h3{
            @extend %withHr;
            position: relative;
        }
    }
    .img{
        @extend %col-sm-5;
        margin-bottom: 20px;
        img{
            width: 100%;
            display: flex;
        }
    }
    .text-full{
        @extend %col-sm-12;
        h1,h2,h3{
            @extend %withHr;
            position: relative;
        }

    }
}
.photogalerie{
    @extend .row;
    .photo{
        @extend %col-6;
        @extend %col-sm-4;
        @extend %col-md-4;
        @extend %col-lg-4;
        @extend %col-xl-3;
        background-color: black;
        border: .5px solid white;
        padding: 0px;
        img{
            width: 100%;
            height: auto;
            display: block;
            opacity: .8;
            transition: opacity .5s;
        }
        &:hover{
            img{
                opacity: 1;
            }
        }
    }
}
.photos{
    @extend %col-12;

    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    .photo,.cat{
        @extend %col-6;
        @extend %col-sm-4;
        @extend %col-md-4;
        @extend %col-lg-4;
        @extend %col-xl-3;
        background-color: black;
        border: .5px solid white;
        padding: 0px;
        img{
            width: 100%;
            height: auto;
            display: block;
            opacity: .8;
            transition: opacity .5s;
        }
        &.photo{
            &:hover{
                img{
                    opacity: 1;
                }
            }
        }

    }
    .cat{
        h4{
            position: absolute;
            bottom: 5px;
            margin: 0px;
            padding: 10px;
            font-size: 17px;
            color: white;
        }
    }
    .title{
        h3{
            font-size: 22px;
            font-weight: 600;
            margin-top: 17px;
            margin-bottom: 17px;
        }
    }
}
.container-fluid{
    .photos{
        @extend .row;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}
.content{
    @extend .row;
    @extend .stretch;
    padding-top: 40px;
    padding-bottom: 40px;
    h1{
        @extend %withHr;
        position: relative;
    }
    .text{
        @extend %col-sm-6;
    }
    .text,.text-full{
        h2{
            font-size: 34px;
            color: black;
            margin: 0px;
            padding: 0px;
            font-weight: 600;
            color: #047832;
        }
        h3{
            font-size: 21px;
            font-weight: 600;
            margin-top: 17px;
            margin-bottom: 17px;
        }
        h4{
            font-size: 17px;
            font-weight: 600;
            color: #666;
            margin: 0px;

        }
    }
    .img{
        @extend %col-sm-6;
        margin-bottom: 20px;
        img{
            width: 100%;
            display: flex;
        }
    }
}
.green{
    background-color: rgba(131, 111, 65,.1);
    h2,h1{
        text-align: center;
        background-position: center;
        &::after{
            display: none;
        }
    }
    .owl-item{
        text-align: center;
    }
    .owl-slider-green{
        @extend %col;
        .owl-dots{
            display: block;
            width: 100%;
            text-align: center;
            div{
                display: inline-block;
                background-color: #524528;
                width: 12px;
                height: 12px;
                margin-left: 5px;
                margin-right: 5px;
                border-radius: 100%;
                opacity: .5;
                &.active{
                    opacity: 1;
                    background-color: #047832;
                }
            }
        }
    }
}
.responsive-table{
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
.text-full{
    @extend %col-sm-12;
    table{
        width: 100%;
        border-collapse: collapse;
        th,td{
            padding: 10px;
            &.align-right{
                text-align: right;
            }
        }
        th{
            padding: 15px 10px 15px 10px;
            background-color: rgba(131, 111, 65,.2);
            color: rgba(131, 111, 65,1);
        }
        td{
            padding: 15px 10px 15px 10px;
            font-size: 15px;
        }
        tr:nth-child(even) {
             td{
                 background-color: rgba(131, 111, 65,.1);
             }
        }
    }
    ul{
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        list-style: none;
        margin-left: 25px;
        li{
            position: relative;
            padding-left: 20px;
        }
        li::before{
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background-color: #047832;
            border-radius: 100%;
            position: absolute;
            top: 9px;
            left: 0px;
        }
    }
}
.sports{
    @extend .row;
    padding-top: 60px;
    margin-bottom: 20px;
    justify-content: center;
    & > div{
        @extend %col-md-3;
        @extend %col-sm-4;
        @extend %col-6;
        text-align: center;
        margin-bottom: 40px;
        img{
            max-width:80px;
        }
        font-size: 13px;
        @include mb('b-xl') {
            max-width: calc(100% / 5);
            max-width: calc(100% / 5);
        }
    }
}
.text-map{
    @extend %col-md-6;
    @extend %col-lg-5;
    @extend %col-sm-12;
}
.map{
    @extend %col-md-6;
    @extend %col-lg-7;
}
.text.green{
    .col{
        @extend %col-md-6;
        padding-bottom: 25px;
        strong{
            color: #836f41;
            font-size: 20px;
        }
        div{
            background-color: white;
            padding: 15px;
            height: 100%;
        }
        &:last-of-type{
            @extend %col-md-12;
        }
    }
}

.icons{
    @extend .row;
    padding-top: 100px;
    padding-bottom: 100px;
    justify-content: center;
    div{
        @extend %col-12;
        @extend %col-sm-6;
        @extend %col-md-2;
        text-align: center;
        font-size: 15px;
        color: #836f41;
        img{
            width: 50%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }
}
#contactForm{
    .form-group{
        @extend %col-md-4;
        input,textarea{
            font-size: 16px;
            padding: 10px;
            border: 1px solid rgba(0,0,0,.3);
            width: 100%;
            outline: none;
        }
        &.error{
            input,textarea{
                border: 1px solid red;
            }
        }
        ul{
            color: red;
            font-size: 12px;
            margin: 0px;
            li{
                padding: 0px;
            }
            li::before{
                display: none;
            }
        }
        &:nth-of-type(7){
            @extend %col-md-12;
        }
        &.submit-btn{
            @extend %col-md-12;
            text-align: right !important;
        }
        button{
            background: none;
            border: none;
            padding: 10px;
            border-radius: 8px;
            background-color: #047832;
            color: white;
            font-weight: bold;
            font-size: 16px;
        }
    }
}
