#news{
    background-image: url(../images/news.jpg);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left top;
    .data{

        @extend %col-sm-8;
        @extend %offset-sm-4;
        background-color: white;
        padding-left: 50px;
        position: relative;
        padding-top: 30px;
        *{
            z-index: 10;
        }
        &::after{
            content: "";
            position: absolute;
            top: 0px;
            width: 1000%;
            height: 100%;
            z-index: 0;
            background-color: white;
        }
    }
    .title{
        h2{
            @extend %withHr;
            padding-bottom: 20px;
            margin-bottom: 20px;
            &::after{
                bottom: 10px;
                width: 100%;
            }
        }
    }
    .articles{
        @extend .row;
        padding-top: 20px;
        padding-bottom: 20px;
        .img{
            @extend %col-sm-4;
            img{
                width: 90%;
                display: flex;
            }
        }
        .text{
            @extend %col-sm-8;
            h3{
                font-size: 30px;
                margin: 0px;
                padding: 0px;
                padding-bottom: 10px;
            }
            span{
                color: #047832;
                font-size: 13px;
                display: block;
            }
            a{
                text-transform: uppercase;
                font-weight: bold;
                color: #bbbbbb;
                text-decoration: none;
                i{
                    font-size: 30px;
                    position: relative;
                    top:5px;
                    margin-right: 15px;
                }
            }
        }
    }
}
.news{
    @extend %col-12;
    .articles{
        @extend %col-lg-4;
        @extend %col-md-6;
        @extend %col-12;
        margin-bottom: 50px;
        .img{
            width: 100%;
            max-width: 100%;
            padding: 0px;
            img{
                width: 100%;
                height: auto;
            }
        }
        .text{
            width: 100%;
            max-width: 100%;
            font-size: 14px;
            text-align: center;
            padding: 0px;
            h3{
                font-size: 25px;
                line-height: 160%;
                margin: 0px;
                padding: 0px;
                text-align: center;
            }
            p{
                text-align: center;
            }
            span{
                color: #047832;
                font-size: 13px;
                display: block;
                text-align: center;
                padding-top: 5px;
            }
            a{
                text-transform: uppercase;
                font-weight: bold;
                color: #bbbbbb;
                text-decoration: none;
                text-align: center;
                display: inline-block;
                i{
                    font-size: 30px;
                    position: relative;
                    top:5px;
                    margin-right: 15px;
                }
            }
        }
    }
}
.new-detail{
    @extend %col-lg-8;
    @extend %offset-lg-2;
    h1{
        text-align: center;
        background: none;
        margin-bottom: 0px;
        &::after{
            display: none;
        }
    }
    p{
        font-size: 17px;
        line-height: 180%;
    }
    time{
        text-align: center;
        display: block;
        color: #524528;
        margin-bottom: 60px;
    }
    img{
        display: block;
        width: 100%;
        margin-bottom: 60px;
    }
}
