#specialoffer{
    background-image: url(../images/specialoffer.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    .title{
        h2{
            @extend %withHr;
            padding-bottom: 20px;
            &::after{
                width: 100%;
            }
        }
    }
    .articles{
        @extend .row;
        padding-top: 40px;
        padding-bottom: 40px;
        .img{
            @extend %col-sm-3;
            img{
                width: 90%;
                display: flex;
            }
        }
        .text{
            @extend %col-sm-9;
            h3{
                font-size: 30px;
                text-transform: uppercase;
                margin: 0px;
                padding: 0px;
            }
            span{
                color: #047832;
                font-size: 13px;
                display: block;
            }
            a{
                text-transform: uppercase;
                font-weight: bold;
                color: #bbbbbb;
                text-decoration: none;
            }
        }
    }
}
