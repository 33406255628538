#slider{
    width: 100%;
    position: relative;
    height: 100vh;
    min-height: 880px;
    overflow: hidden;
    color: var(--color-1);
    @include mb('slider-sliderNav-col') {
        overflow: visible;
        height: auto;
    }

    .owl-slider{
        max-height: calc(100vh - 60px);
        min-height: 880px;
        overflow: hidden;
        @include mb('b-xs') {
            min-height: auto;
        }
        .owl-nav{

            div{
                position: absolute;
                top:50%;
                z-index: 100;
                font-size: 30px;
                &:first-of-type{
                    left: 15px;
                    transform: rotate(-180deg);
                }
                &:last-of-type{
                    right: 15px;
                }
            }


        }
    }
    .item::before{
        content: "";
        display: block;
        position: absolute;
        z-index: 100;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);

    }
    .item{
        width: 100%;
    }
    .text{
        @extend %col-12;
        @extend %col-lg-7;
        @extend %offset-lg-2;
        position: absolute;
        top: 25%;
        z-index: 999 !important;
        padding-left: 45px;
        padding-right: 45px;
        h2{
            margin: 0px;
            padding: 0px;
            font-size: 56px;
            line-height: 56px;
            color: white;
            font-weight: 600;
        }
        p{

        }
        @include mb('slider-sliderNav-col') {
            &{
                top: 35%;
                text-align: center;
            }
        }
        @include mb('slider-text') {
            &{
                top: 45%;
                text-align: center;
                h2{
                    font-size: 30px;
                    line-height: 33px;
                }
            }
        }
        @include mb('head-menu-xs') {
            h2{
                font-size: 25px;
                line-height: 27px;
            }
            p{
                font-size: 14px;
                padding: 15px;
            }
        }

    }
    .sliderNav{
        position: absolute;
        bottom: 0px;
        width: 100%;
        z-index: 100;
        .col{
            @extend %col;
            width: calc(100%/5);
            padding: 0px;
            cursor: pointer;
            div,a{
                background-color: #BFAB7E;
                padding: 15px;
            }
            & > div{
                height: calc(100% - 55px);
            }
            a{
                color: white;
                text-decoration: none;
                font-size: 18px;
                font-weight: bold;
                opacity: 0;
                display: block;
                i{
                    margin-right: 10px;
                    font-size: 20px;
                    position: relative;
                    top: 2px;
                }
            }
            &:hover{
                a{
                    opacity: 1;
                }
            }
            big{
                font-size: 20px;
                font-weight: bolder;
            }
            div{

                i{
                    display: block;
                    font-size: 30px;
                    margin-bottom: 15px;
                    float: left;
                    margin-right: 10px;
                }
                big{
                    display: block;
                    width: calc(100%);
                    padding-left: 20px;
                    margin-top: 2px;
                    &::after{
                        content: "";
                        clear: both;
                        display: table;
                    }
                }
            }
            p{
                font-size: 16px;
                min-height: 100px;
            }
            &:nth-of-type(2){
                div,a{
                    background-color: #AD9358;
                }

            }
            &:nth-of-type(3){
                div,a{
                    background-color: #836F41;
                }
            }
            &:nth-of-type(4){
                div,a{
                    background-color: #524528;
                }
            }
            &:nth-of-type(5){
                div,a{
                    background-color: #473D25;
                }
            }
        }
        @include mb('slider-sliderNav-col') {
            &{
                position: static;
                .col{
                    flex: 0 calc(100%/3);
                    a{
                        opacity: 1 !important;
                    }
                    &:nth-child(4),&:nth-child(5){
                        flex: 0 calc(100%/2);
                    }
                }

            }
        }
        @include mb('slider-sliderNav-col-xs') {
            &{
                position: static;
                .col{
                    flex: 0 calc(100%/2);
                    &:nth-child(5){
                        flex: 0 100%;
                    }
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
