#head{
    position: absolute;
    display: block;
    z-index: 100;
    width: 100%;
    .head{
        @extend %col-md-12;
        text-align: center;
        position: relative;
        a.logo{
            float: left;
            margin-top: 30px;
            position: relative;
            img{
                width: 130px;
                z-index: 101;
                position: relative;
            }
            &::before{
                content: "";
                width: 450px;
                height: 250px;
                background-color: white;
                position: absolute;
                z-index: 1;
                transform: rotate(25deg);
                left: -260px;
                top: -160px;
                -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
                -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.4);
            }
        }
        ul.langs{
            @extend %ul.default;
            float: right;
            font-size: 16px;
            margin-top: -30px;
            li{
                display: inline-block;
                margin-left: 10px;
                img{
                    width: 17px;
                }
                &.buttons{
                    margin-left: 5px;
                    &:first-of-type{
                        a{
                            background-color: #AD9358;
                        }
                    }
                    a{
                        font-size: 13px;
                        color: black;
                        background-color: #524528;
                        border-radius: 8px;
                        padding: 5px 10px 5px 10px;
                        color: white;
                        font-weight: bold;
                        text-decoration: none;
                    }
                }
            }

        }
        .mobileNav{
            position: absolute;
            right: 15px;
            font-size: 30px;
            color: white;
            z-index: 999;
            margin-top: 50px;
            display: none;
        }
        ul.langs{
            @extend %ul.default;
            li{
                display: inline-block;
            }
            position: absolute;
            right: 15px;
            top: 60px;
        }
        ul.menu{
            @extend %ul.default;
            float: right;
            margin-top: 77px;
            font-size: 16px;
            li{
                margin-left: 15px;
                margin-right: 15px;
                display: inline-block;
                position: relative;
                padding-bottom: 15px;
                &:last-of-type{
                    margin-right: 0px;
                }
                a{
                    color: white;
                    text-decoration: none;
                    padding-bottom: 5px;
                    display: block;
                }
                &.active,&:hover{
                    a{
                        border-bottom: 1px solid white;
                        text-shadow: 1px 1px rgba(0,0,0,.5);
                    }
                }
                @include mb('head-menu-sm') {
                    margin-left: 10px;
                    margin-right: 0px;
                }
                &:hover{
                    & > ul{
                        display: block;
                        z-index: 100;
                    }
                }
                & > ul{
                    position: absolute;
                    background-color: white;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 0px;
                    min-width: 180px;
                    margin-top: 15px;
                    padding: 5px;
                    display: none;
                    -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
                    -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
                    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.3);
                    &::before{
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid white;
                        left: 0px;
                        top: -10px;
                        position: absolute;
                    }
                    li{
                        margin: 0px;
                        padding: 5px 10px 5px 10px;
                        display: block;
                        text-align: left;
                        border: none !important;
                        text-shadow: none !important;
                        a{
                            color: black;
                            border: none !important;
                            text-shadow: none !important;
                            font-size: 14px;
                            &:hover{
                                color:  #047832;
                            }
                        }
                    }
                }
            }
        }
        @include mb('head-menu-xs') {
            ul.langs{
                display: none;
            }
            ul.menu{
                display: none;
                background-color: white;
                position: fixed;
                width: 100%;
                left: 0px;
                top: 0px;
                z-index: 999;
                margin-top: 0px;
                height: 100vh;
                overflow-y: scroll;
                padding-top: 100px;
                padding-bottom: 100px;
                li{
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    &.active,&:hover{
                        a{
                            text-shadow: none !important;
                        }
                    }
                    a{
                        color: black;
                        text-shadow: none;
                        border: none !important;
                    }
                    ul{
                        position: static;
                        margin-top: 0px;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        &::before{
                            display:none;
                        }
                        li{
                            a{
                                text-align: center;
                                color: #006b2a;
                            }
                        }
                    }
                }
            }
            .mobileNav{
                display: block;
                cursor: pointer;
            }
            a.logo{
                float: left;
                margin-top: 20px;
                position: relative;
                img{
                    width: 100px;
                    z-index: 101;
                    position: relative;
                }
                &::before{
                    content: "";
                    width: 420px;
                    height: 220px;
                    background-color: white;
                    position: absolute;
                    z-index: 1;
                    transform: rotate(25deg);
                    left: -260px;
                    top: -160px;
                }
            }
        }
    }
}
.subPage{
    #head{
        background-image: url(../images/subPageBg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        height: 190px;
        position: static;
        @include mb('b-xs') {
            background-image: url(../images/subPageBgxs.jpg);
        }
    }
}
#head.active{
    ul.menu{
        display: block;
    }
    ul.langs{
        display: block;
        z-index: 1000;
        top: 85px;
        left: 15px;
    }
    .mobileNav{
        color: black;
        z-index: 1000;
    }
}
