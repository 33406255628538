:root{
    --text-size-1: #{30px};
    --text-size-2: #{50px};
    --text-size-3: #{20px};
    --text-size-4: #{15px};
    --text-size-5: #{9px};
    --text-size-6: #{22px};
    --text-size-7: #{16px};
    --text-size-8: #{67px};
    --text-size-9: #{25px};
    --color-1: #{#FFFFFF};
    --color-2: #{#FEFFE2};
    --color-3: #{#FF5F4A};
    --color-4: #{#F3F5CB};
    --color-5: #{#0074A5};
    --color-6: #{#000000};
    --color-7: #{#67AD51};
    --color-8: #{#FCE266};
    --color-9: #{#707070};
    --color-10: #{#808080};
    --color-11: #{#000029};


    --shadow-size: #{17px};
    --shadow-color: #{rgba(0, 0, 0, 0.4)};
}
