@mixin mb($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}


$breakpoints: (
  'head-menu-sm': ( max-width:  1416px ),
  'head-menu-xs': ( max-width:  1116px ),
  'slider-sliderNav-col': ( max-width:  991px ),
  'slider-sliderNav-col-xs': ( max-width:  757px ),
  'slider-text': ( max-width:  678px ),
  'mobileNavSubpage-xs': ( max-width:  678px ),
  'head-ul-li-sm': ( max-width:  1015px ),
  'header-md': ( max-width:  1183px ),
  'head-xs': ( max-width:  776px ),
  'b-xs': ( max-width: 575.98px ),
  'b-sm': ( max-width: 767.98px ),
  'b-md': ( max-width: 991.98px ),
  'b-lg': ( max-width: 1199.98px ),
  'b-xl': ( min-width: 1200px ),

) !default;
