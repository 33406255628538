@use "sass:meta";

@import "options/root";
@import "options/break-points";
@import "bootstrap_extend/grid";

%ul.default{
    margin: 0px;
    padding: 0px;
    list-style: none;
}

html,body{
    overflow-x: hidden;
}
body{
    margin: 0px;
    padding: 0px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    background-color: white;
    font-size: 18px;
    color: #666666;
    &.locked{
        max-height: 100vh;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        position: fixed;
    }
}
.text-center{
    text-align: center;
}
.btns{
    @extend %col-sm-12;
    padding-top: 25px;
    padding-bottom: 25px;
    a{
        text-decoration: none;
        font-weight: bold;
        i{
            font-size: 30px;
            position: relative;
            top: 5px;
            margin-right: 15px;
        }
    }
    &.back{
        text-align: center;
        i{
            display: inline-block;
            transform: rotate(180deg);
        }
    }
}
%img-responsive{
    max-width: 100%;
}
%transition{
    -webkit-transition: all .5s; /* Safari prior 6.1 */
    transition: all .5s;
}
h1,h2,h3,h4,h5{
    font-weight: 600;
    color: black;
    font-size: 37px;
    line-height: 130%;
}
a{
    cursor: pointer;
    color: #047832;
}
.img-parallax {
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  pointer-events: none
}
.stretch{
    //align-items: stretch;
    //justify-content: center;
    align-items: center;
}
.container {
    @extend %container;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.title{
    @extend %col-sm-12;
}

%withHr{
    padding-bottom: 40px;
    &::after{
        content: "";
        width: 80%;
        height: 1px;
        display: block;
        bottom: 0px;
        background-color: #ededed;
        position: absolute;
        background: #047832;
        background: -moz-linear-gradient(left, #047832 20%, #ededed 20%, #ededed 100%);
        background: -webkit-linear-gradient(left, #047832 20%,#ededed 20%,#ededed 100%);
        background: linear-gradient(to right, #047832 20%,#ededed 20%,#ededed 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#047832', endColorstr='#ededed',GradientType=1 );
    }
}
.mobileNavSubpage{
    display: none;
    ul{
        @extend %col-sm-12;
        list-style: none;
        text-align: center;
        margin-top: 35px;
        li{
            display: inline-block;
            padding: 5px;
            a{
                display: block;
                border-radius: 3px;
                border: 1px solid #047832;
                padding: 5px 10px 5px 10px;
                text-decoration: none;
                font-size: 14px;
            }
        }
    }

    @include mb('head-menu-xs') {
        &{
            //display: block;
        }
    }

    @include mb('mobileNavSubpage-xs') {
        &{
            ul{
                display: flex;
                flex-wrap: wrap;
                li{
                    width: 50%;
                    //flex: 0 50%;
                }
            }
        }
    }


}


@import "compoments/head";
@import "compoments/slider";
@import "compoments/article";
@import "compoments/testimonial";
@import "compoments/specialoffer";
@import "compoments/news";

@import "compoments/footer";

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1000px) and (max-width:1200px) {
  .container {
    max-width: 100%;
    width: 100%;
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media screen and (min-width:1400px) and (max-width:1680px) {
    .container{
        width: 80%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    #head .container{
        width: 90%;
    }

}
@media (min-width:1681px) {
    .container{
        width: 1600px;
        max-width:1600px;
        margin-left: auto;
        margin-right: auto;
    }

}

.cookies{
        position: fixed;
        bottom: 0px;
        background-color: white;
        z-index: 998;
        width: 100%;
        padding: 15px;
        text-align: center;
        font-size: 13px;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.2);
        display: none;
        color: black;
        &.active{
            display: block;
        }
        a{
            color: #ad9358;
            text-decoration: none;
            border-bottom: 1px solid #ad9358;
            padding-bottom: 4px;
            cursor: pointer;
            &:hover{
                border-bottom: none;
            }
        }
        .accept-cookies{
            cursor: pointer;
            background-color: black;
            color: white;
            padding: 10px;
            font-weight: bold;
            margin-left: 25px;
            border: none;
            white-space: nowrap;
        }
        @media screen and (max-width:566px) {
            .accept-cookies{
                display: block;
                width: auto;
                margin-top: 10px;
            }
        }
}

.m-cookie{
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0,0,0,.4);
    padding-top: 40px;
    overflow: auto;
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-bottom: 100px;
    display: none;
    color: black;
    a{
        cursor: pointer;
    }
    &.active{
        position: fixed;
        left:0px;
        top: 0px;
        z-index: 999;
        display: block;
        height: 100vh;
        overflow: scroll;
    }
    .box{
        @extend %col-sm-10;
        @extend %offset-sm-1;
        @extend %col-md-6;
        @extend %offset-md-3;
        background-color: white;
        padding: 25px;
        font-size: 14px;
        padding-top: 60px;
        h1,h2,h3{
            color: black;
            font-size: 22px !important;
            margin: 0px !important;
            padding: 0px !important;
        }
        a{
            color: #047832;
        }
        .close{
            position: absolute;
            right: 25px;
            top: 15px;
            color: black;
            cursor: pointer;
            z-index: 999;
        }
    }
}
