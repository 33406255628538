#footer{
    width: 100%;
    background-color:#524528;
    color: white;
    font-size: 16px;

    .container{
        background-image: url(../images/logo-footer.svg);
        background-repeat: no-repeat;
        background-position: right;
        padding-bottom: 100px;
    }
    h4{
        color: white;
        font-size: 30px;
        line-height: 34px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        &::after{
            z-index: 3;
            content: "";
            width: 100%;
            height: 1px;
            display: block;
            bottom: 0px;
            background-color: #ededed;
            position: absolute;
            background: #047832;
            background: -moz-linear-gradient(left, #ffffff 20%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 100%);
            background: -webkit-linear-gradient(left, #ffffff 20%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 100%);
            background: linear-gradient(to right, #ffffff 20%,rgba(0,0,0,0) 20%,rgba(0,0,0,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#36935b',GradientType=1 );
        }
    }
    p{
        margin: 0px;
        padding: 0px;
    }
    a{
        color: white;
        text-decoration: none;
    }
    .col:first-of-type{
        @extend %col-md-9;
    }
    .col:last-of-type{
        @extend %col-md-3;
        ul{
            @extend %ul.default;
            li{
                padding: 5px 0px 5px 0px;
                a{
                    font-size: 20px;
                }
            }
        }
    }
    .fifty{
        &:first-of-type{
            @extend %col-md-5;
        }
        &:last-of-type{
            @extend %col-md-7;
        }
        img{
            width: 100%;
        }
        .social{
            display: inline-block;
            margin-top: 25px;
            margin-right: 15px;
            img{
                width: 40px;
            }
        }
    }
}
#copyright{
    background-color: #473d25;
    color: white;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    a{
        font-weight: bold;
        text-decoration: none;
        color: white;
    }
    @include mb('head-menu-xs') {
        padding: 15px;
    }
}
