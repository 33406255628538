.testimonial{
    width: 100%;
    background-image: url(../images/testimonial.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    .testi{
        @extend %col-10;
        @extend %offset-1;
        @extend %col-sm-5;
        @extend %col-md-4;
        padding: 40px 40px 40px 40px;
        border: 1px solid #047832;
        position: relative;
        top: -30px;
        big{
            font-size: 34px;
            font-weight: 900;
            display: block;
            margin-bottom: 15px;
        }
    }
    .link{
        @extend %col-10;
        @extend %offset-1;
        @extend %col-sm-6;
        @extend %col-md-5;
        @extend %offset-md-2;
        font-size: 34px;
        padding-top: 20px;
        a{
            text-transform: uppercase;
            color: white;
            font-size: 18px;
            text-decoration: none;
        }
    }
}
